import { useState, useReducer, useRef, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useBeforeUnload } from 'react-router-dom';
import { first } from 'rxjs/operators';
import { _authService } from '../../../../app/core/service/auth.service';
import { NotificationService } from '../../../../app/core/service/notification.service';
import useAbortController from '../../../hooks/useAbortController';
import useService from '../../../hooks/useService';
import {
  NotificationOffset,
  NotificationStatus,
  Notification,
  NotificationsListType
} from '../models';
import { DEFAULT_NOTIFICATIONS_PAGE_SIZE } from '../constants';

const pageSize: number = DEFAULT_NOTIFICATIONS_PAGE_SIZE;

const initialNotificationState = {
  notifications: [],
  newCount: 0
};

function notificationsReducer(state, action) {
  const { type, data } = action;
  switch (type) {
    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: data };
    case 'SET_NEW_COUNT':
      return { ...state, newCount: data };
    default:
      return {
        ...state
      };
  }
}

export const useNotifications = ({ dataSource, entity }) => {
  const _notificationService = useService(NotificationService);
  const [delimiterPosition, setDelimiterPosition] = useState(0);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loadingTop, setLoadingTop] = useState(false);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const [state, dispatch] = useReducer(notificationsReducer, initialNotificationState);
  const { abortController, abort } = useAbortController();
  const { notifications, newCount } = state;
  useBeforeUnload(() => {
    _notificationService.markRead(entity, []).subscribe();
    _notificationService.updateUnreadCount({ [entity]: 0 });
  });

  const canTriggerNotificationsFetchAPI = useRef(true);

  const [topSentryRef] = useInfiniteScroll({
    loading: loadingTop,
    hasNextPage: canTriggerNotificationsFetchAPI.current,
    disabled: false,
    onLoadMore: () => {
      subscribeToEdgeScroll('top');
    },
    rootMargin: '0px 0px 0px 0px'
  });

  const [bottomSentryRef] = useInfiniteScroll({
    loading: loadingBottom,
    hasNextPage: canTriggerNotificationsFetchAPI.current,
    onLoadMore: () => {
      subscribeToEdgeScroll('bottom');
    },
    disabled: false,
    rootMargin: '0px 0px 20px 0px'
  });

  useEffect(() => {
    _notificationService.unreadCount$.pipe().subscribe(unreadCount => {
      dispatch({ type: 'SET_NEW_COUNT', data: unreadCount[entity] });
    });

    const notificationsObservable = _notificationService.notifications$
      .pipe(first())
      .subscribe(existingNotifications => {
        if (existingNotifications[entity].length) {
          dispatch({ type: 'SET_NOTIFICATIONS', data: existingNotifications[entity] });
        } else {
          getMostRecent();
        }
      });

    if (notifications.length <= pageSize) {
      dispatch({ type: 'SET_NEW_COUNT', data: 0 });
    }

    clearNotificationsOnLogout();

    // unSubscribe on unmount
    return () => {
      _notificationService.markRead(entity, []).subscribe();
      _notificationService.updateUnreadCount({ [entity]: 0 });
      notificationsObservable.unsubscribe();
    };
  }, []);

  const clearNotificationsOnLogout = () =>
    _authService.beforeLogoutSubject.subscribe(() => {
      _notificationService.setNotifications({ [entity]: [] });
      _notificationService.updateUnreadCount({ [entity]: 0 });
      _notificationService.updateLatestFetchNotificationOffset({
        [entity]: { id: null, createdTs: null }
      });
    });

  useEffect(() => {
    delimiterPositionSetter();
  }, [notifications]);

  const subscribeToEdgeScroll = async edgeDir => {
    if (edgeDir === 'top') {
      setLoadingTop(true);
    } else {
      setLoadingBottom(true);
    }

    const payload: NotificationOffset = {
      direction: edgeDir,
      pageSize
    };

    if (notifications.length && edgeDir === 'bottom' && entity === NotificationsListType.REGULAR) {
      const lastN: Notification = notifications[notifications.length - 1];
      payload.timestamp = lastN.createdTs;
      payload.id = lastN.id;
    }

    if (
      notifications.length &&
      edgeDir === 'bottom' &&
      entity === NotificationsListType.ENTERPRISE
    ) {
      payload.cursor = _notificationService.getLatestFetchNotificationOffsetForEntity(
        entity
      ) as unknown as string;
    }

    canTriggerNotificationsFetchAPI.current = false;

    await getNotifications(payload);

    if (edgeDir === 'top') {
      setLoadingTop(false);
    } else {
      setLoadingBottom(false);
    }
  };

  const getNotifications = async payload => {
    abort();

    const data = await dataSource(payload, {
      signal: abortController.signal
    });

    if (entity === NotificationsListType.REGULAR && !data.length) {
      return;
    }

    if (entity === NotificationsListType.ENTERPRISE && !data.notifications.length) {
      return;
    }

    let _notifications = [];

    if (entity === NotificationsListType.REGULAR) {
      _notifications = filterUniqueNotifications(data);
    } else {
      _notifications = filterUniqueNotifications(data.notifications);
    }

    dispatch({ type: 'SET_NEW_COUNT', data: 0 });

    if (entity === NotificationsListType.REGULAR) {
      if (!payload.timestamp) {
        const lastN = data[0];
        const viewedOffset = {
          id: lastN.id,
          createdTs: lastN.createdTs
        };

        _notificationService.updateLatestFetchNotificationOffset({ [entity]: viewedOffset });

        dispatch({ type: 'SET_NOTIFICATIONS', data: _notifications });
        _notificationService.setNotifications({ [entity]: _notifications });
      } else {
        dispatch({ type: 'SET_NOTIFICATIONS', data: _notifications });
        _notificationService.setNotifications({
          [entity]: _notifications
        });
      }
    } else {
      dispatch({ type: 'SET_NOTIFICATIONS', data: _notifications });
      _notificationService.setNotifications({
        [entity]: _notifications
      });
      _notificationService.updateLatestFetchNotificationOffset({ [entity]: data.next_cursor });
    }
  };

  const filterUniqueNotifications = data => {
    const newNotifications = [...notifications, ...data];
    return newNotifications.filter(
      (notification, index) =>
        newNotifications.findIndex(item => item.id === notification.id) === index
    );
  };

  const getMostRecent = async () => {
    dispatch({ type: 'SET_NEW_COUNT', data: 0 });
    _notificationService.updateUnreadCount({ [entity]: 0 });
    setInitialLoading(true);

    await getNotifications({
      pageSize,
      direction: 'top'
    });

    setInitialLoading(false);
  };

  const delimiterPositionSetter = () => {
    let position: number;
    notifications.forEach((notification: Notification, index: number) => {
      if (notification.status === NotificationStatus.UNREAD) {
        position = index;
      }
    });

    setDelimiterPosition(position ? position + 1 : 0);
  };

  return {
    loadingTop,
    initialLoading,
    newCount,
    getMostRecent,
    notifications,
    loadingBottom,
    canTriggerNotificationsFetchAPI,
    topSentryRef,

    bottomSentryRef,
    delimiterPosition
  };
};
